/* General Styles */
html, body {
  height: 100%; 
  min-width: 300px; 
  /* Enables scrolling if viewport is smaller than the content */
  overflow: auto; 
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Layout with Flexbox */
body {
  display: flex; 
  flex-direction: column; 
  min-height: 100vh; /* Use viewport height to ensure full height */
  color: #000000;
}

.App {
  /* Allows the app container to fill the available space */
 flex-grow: 1;
 min-height: 100vh; /* Ensures it takes at least the full viewport height */
 display: flex; 
 flex-direction: column; 
 /* Make sure the app is vertically centered */
 justify-content: center;
 text-align: center;
}


/* Footer Styling */
.footer {
  margin-top: auto; 
  /* color: gray; */
  color: #6B7280;
}

footer p {
  font-size: 0.9rem;
  margin: 20px 0; 
  color: #6B7280;
}

.social-media {
  display: flex;
  justify-content: center; /* Center icons horizontally */
  gap: 25px; /* Spacing between icons */
  /* margin: 20px 0 50px 0;  */
  margin: 30px; 
  color: #2679F3;
}

.social-icon {
  font-size: 1.75rem; /* Icon size */
  color: #333; /* Icon color */
}

.social-icon:hover {
  /* color: #282c34; */
  color: rgb(22, 243, 243);
  cursor: pointer; 
  transform: scale(1.2);
}