.waitlist-form {
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    /* flex-basis: 50%; */
    /* width: 100%;  */
    background-color: #fff;
    padding: 10px;
    border-radius: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* box-shadow: 2px 8px 12px rgba(0, 0, 0, 0.1); */
}

.form-label {
    font-size: 15px; 
    font-weight: 600; 
    margin-right: 5px; 
    /* padding-right: 10px;  */
    /* margin-bottom: 10px;  */
}

.form-input {
    margin: 0px 5px; 
    /* padding: 3px 5px 3px 10px; */
    margin: 0 5px; 
    padding: 5px;
    font-size: 18px; 
    border-radius: 20px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    flex-grow: 1; 
}

.form-input:hover, .form-input:focus {
    border: 2px solid rgba(208, 236, 246) !important;
    outline: none; 
}

.btn-signup, .btn-cancel {
    padding: 8px 16px;
    margin: 5px;
    border: none;
    border-radius: 20px;
    align-items: center;
    font-size: 14px; 
    font-weight: 600;
}

.btn-signup:hover, .btn-cancel {
    cursor: pointer;
    font-weight: 600;
}

.btn-signup {
    color: black; 
    background-color: rgba(208, 236, 246);
    box-shadow: 0 10px 20px -10px #2679F3;
}

.btn-signup:hover {
    color: #fff;
    background-color:#2679F3;
}

.btn-cancel {
    background-color: #EFEFEF; 
    box-shadow: 0 10px 20px -10px #666;
}

.btn-cancel:hover {
    background-color: white; 
}

@media (max-width: 800px) {
    .waitlist-form {
        flex-direction: column; 
        width: 100%; 
        padding: 20px; 
    }

    .form-label {
        margin-bottom: 10px; 
    }

    /* .form-input {
        width: 100%; 
    } */

    .btn-group {
        display: flex; 
        flex-direction: column; 
        width: 100%; 
    }

    
}