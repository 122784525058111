.page-wrapper {
  align-items: center;
}

.top-container {
  display: flex; 
  flex-direction: column;
  margin: 80px 20px; 
  justify-content: center;
  align-items: center; 
}

.logo-container {
  display: flex; 
  flex-direction: row; 
  align-items: center;
}

.logo-link-bg {
  text-decoration: none;
}

.logo-bg {
  font-size: 2.2rem; 
  font-weight: 800;  
  letter-spacing: -0.05rem;
  color:#2679F3;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}


.btn-beta {
    margin-left: 8px; 
    padding: 5px 10px 5px 10px;
    border: none;
    color: #2679F3;
    background-color: #EFF6FF;
    font-size: 0.75rem;
    border-radius: 20px;
    letter-spacing: 0.025rem; 
    font-weight: 900; 
    box-shadow: 0 10px 20px -10px #EFF6FF;
  }

  .header-container {
    margin: 20px 0px; 
  }
  
  .header-tagline {
    margin-top: 40px; 
    padding: 0px;
    font-weight: 500;
    letter-spacing: 0rem;
    align-items: left;
    color: #6B7280;
  }
  
  .tagline-text {
    font-size: 20px; 
    font-weight: 400;
  }

  .top-container p {
    font-family: Arial;
    font-size: 1.5rem; 
    font-weight: 800; 
    color: #6B7280;
    text-align: left;
    overflow: hidden; 
    padding: 0.5rem
  }

  .container-text {
    font-size: 3rem; 
    font-weight: 800; 
    text-align: left;
    overflow: hidden; 
    margin: 12px;
    padding: 0.5rem;
  }

  .page-header {
    margin: 10px 0px 20px 0px; 
    font-size: 2.5rem;
    font-weight: 700; 
    letter-spacing: -0.05rem;
  }

  .typed-text {
    margin: 10px 0px; 
    font-size: 3.3rem; 
    font-weight: 800;  
    color: #2679F3;
    align-items: left;
  }

  .company-tags {
    display: flex; 
    flex-direction: row;
    align-items: center;
    padding: 12px 0px;
  }

  .company-tag-1 {
    margin: 0px 5px;
    padding: 5px;
    font-size: 1.25rem;
    font-weight: 600;
    background-color: rgb(176 235 243);
    border-radius: 5px;
    border: transparent;
  }

  .company-tag-2 {
    margin: 0px 5px;
    padding: 5px;
    font-size: 1.25rem;
    font-weight: 600;
    background-color: rgb(186 246 211);
    border-radius: 5px;
    border: transparent;
  }

  .company-tag-3 {
    margin: 0px 5px;
    padding: 5px;
    font-size: 1.25rem;
    font-weight: 600;
    background-color: rgb(255 239 166);
    border-radius: 5px;
    border: transparent;
  }

  .company-tag-4 {
    margin: 0px 5px;
    padding: 5px;
    font-size: 1.25rem;
    font-weight: 600;
    background-color: rgb(252 194 208);
    border-radius: 5px;
    border: transparent;
  }
  
  .cursor {
    display: inline-block; 
    width: 3px; 
    background-color: #ccc; 
    margin-left: 0.1rem; 
    animation: blink 1s infinite; 
  }

  @keyframes blink {
    0% { background-color: #ccc; }
    49% { background-color: #ccc; }
    50% { background-color: transparent; }
    99% { background-color: transparent; }
    100% { background-color: #ccc; }
  }

  h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  h3 {
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }


.signup-container {
  margin: 20px 0px; 
}

.signup-container p {
  margin: 10px 0px; 
  font-family: Arial;
  font-size: 1rem; 
  font-weight: 500; 
  color: #6B7280;
  text-align: left;
  overflow: hidden; 
}

.form-container {
  display: flex; 
  align-items: center; 
  justify-content: center; 
}

.btn-join {
  padding: 15px 30px;
  margin: 10px 0px;
  border: none;
  border-radius: 25px;
  align-items: center;
  font-size: 18px; 
  font-weight: 500;
   color: #fff;
  background-color:#2679F3;
  box-shadow: 0 10px 20px -10px #2679F3;
}

.btn-join:hover {
    cursor: pointer;
    font-size: 18px; 
    font-weight: 600;
    color: black; 
    background-color: rgba(208, 236, 246);
    box-shadow: 0 10px 20px -10px rgb(22, 243, 243, 0.5);
} 

